.employee-table-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
}

.employee-table-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.employee-table {
    width: 100%;
    border-collapse: collapse;
}

.employee-table-header {
    background-color: #4CAF50;
    color: white;
}

.employee-table-header-cell {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.employee-table-row:nth-child(even) {
    background-color: #f2f2f2;
}

.employee-table-cell {
    padding: 10px;
    border: 1px solid #ddd;
}

.employee-table-delete-button {
    padding: 5px 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.employee-table-delete-button:hover {
    background-color: #d32f2f;
}
