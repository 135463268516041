.enquiry-form-home-page{
  height:120vh;
  width: 70vw;
  border: 1px solid black;
  display: flex;
  
}
.enquiry-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: auto;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #000;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #000;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .form-group .error {
    color: red;
    font-size: 0.875em;
    margin-top: 5px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #444;
  }
  