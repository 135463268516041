.enquiry-followup-container {
    padding: 20px;
    margin: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
  }
.enquiry-followup-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .enquiry-followup-table {
    width: 100%;
    border-collapse: collapse;
    animation: slideIn 0.5s ease-in-out;
  }
  
  .enquiry-followup-header {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    text-align: left;
  }
  
  .enquiry-followup-row:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .enquiry-followup-cell {
    padding: 10px;
    text-align: left;
  }
  
  .enquiry-followup-loading,
  .enquiry-followup-error {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }

  .filter-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .filter-label {
    margin-right: 5px;
    font-weight: bold;
  }
  
  .filter-date {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .filter-button {
    padding: 6px 12px;
    font-size: 14px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .filter-button:hover {
    background-color: #0056b3;
  }
  
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
    }
    to {
      transform: translateY(0);
    }
  }
  