.center-home-page-body{
    height: 80vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.center-home-page-card-logo{
    color:black;
    font-size:50px;
}
.center-home-page-card{
    border:1px solid black;
    padding:20px;
    text-align: center;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
}
