.courses-card {
    width: calc(28.333% - 20px);
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s;
    padding-bottom: 20px;
  }
  
  .courses-card:hover {
    transform: translateY(-5px);
  }

.all-courses-buttons {
    display: flex;
    gap: 10px;
  }
.all-courses-edit-button,
.all-courses-delete-button {
  flex: 1;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  margin: 20px;
}

.all-courses-edit-button {
  background-color: #4caf50;
  color: white;
}

.all-courses-edit-button:hover {
  background-color: #45a049;
}

.all-courses-delete-button {
  background-color: #f44336;
  color: white;
}

.all-courses-delete-button:hover {
  background-color: #e53935;
}

.all-courses-loading, .all-courses-error, .all-courses-no-courses {
  font-size: 18px;
  color: #333;
  text-align: center;
  margin-top: 20px;
}