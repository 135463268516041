
.login-container {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    animation: fadeIn 0.8s ease-in-out;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .login-title {
    text-align: center;
    margin-bottom: 20px;
    color: #000;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .login-label {
    margin-bottom: 5px;
    color: #000;
  }
  
  .login-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .password-container {
    display: flex;
    align-items: center;
  }
  
  .show-password-button {
    background: none;
    border: none;
    color: #f03636;
    cursor: pointer;
    margin-left: 10px;
    font-size: 0.9em;
  }
  
  .login-button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background: #000;
    color: white;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .login-button:hover {
    background: #151515;
    
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  