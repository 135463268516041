.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: popupFadeIn 0.3s ease-in-out;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: popupSlideIn 0.3s ease-in-out;
  }
  
  .popup-textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .popup-input {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .popup-save, .popup-close {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .popup-save {
    background-color: #4CAF50;
    color: white;
  }
  
  .popup-close {
    background-color: #f44336;
    color: white;
  }
  
  @keyframes popupFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes popupSlideIn {
    from {
      transform: translateY(-20px);
    }
    to {
      transform: translateY(0);
    }
  }
  .studentJoinStatus-search {
    padding: 8px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .studentJoinStatus-filters {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  
  .studentJoinStatus-filter {
    margin-right: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  