/* Styles for the JoinedAdmission component */
.project-admission-container {
  padding: 20px;
}

.project-admission-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.project-admission-count {
  font-size: 18px;
  font-weight: bold;
}

.project-admission-filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-input, .filter-select {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.project-admission-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.project-admission-table th, .project-admission-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.project-admission-table th {
  background-color: #f0f0f0;
}

.project-admission-popup, .chrono-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 1000;
  width: 50%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height:70vh;
  overflow: auto;
}

.project-admission-popup-content label, .chrono-popup-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  
}

.project-admission-popup-content h2 {
  margin-bottom: 10px;
}

.project-admission-popup-close, .chrono-popup-close {
  margin-top: 10px;
  align-self: flex-end;
  padding: 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.project-admission-popup-close:hover, .chrono-popup-close:hover {
  background-color: #d32f2f;
}

.project-admission-no-data, .project-admission-loading, .project-admission-error {
  text-align: center;
  font-size: 20px;

}

.project-admission-popup input, .project-admission-popup textarea {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.project-admission-popup button, .chrono-popup button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.project-admission-popup button:hover, .chrono-popup button:hover {
  background-color: #f0f0f0;
}
