.employee-login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.employee-login-form {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.employee-login-form-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #333;
}

.employee-form-group {
    margin-bottom: 1rem;
}

.employee-form-label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
    font-weight: bold;
}

.employee-form-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
}

.employee-show-password-button {
    margin-top: 0.5rem;
    background-color:#007BFF;
    border: none;
    color: #fcfdfd;
    cursor: pointer;
}


.employee-submit-button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #007BFF;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.employee-submit-button:hover {
    background-color: #0056b3;
}
