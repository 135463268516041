.employee-registration {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #000;
    background-color: #fff;
  }
  
  .registration-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #000;
  }
  
  .form-input,
  .form-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #000;
    border-radius: 4px;
    color: #000;
    background-color: #fff;
  }
  
  .form-checkbox {
    margin-right: 5px;
  }
  
  .form-label-checkbox {
    font-weight: bold;
    color: #000;
  }
  
  .form-button {
    padding: 10px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-button:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
  }
  