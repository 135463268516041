/* src/styles.css */
.table-container {
    padding: 20px;
    margin: auto;
    max-width: 1500px;
}

.table-title {
    text-align: right;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.enquiry-table {
    width: 80%;
    border-collapse: collapse;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    animation: fadeIn 1s ease-in-out;
}

.enquiry-table th, .enquiry-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.enquiry-table th {
    background-color: #f4f4f4;
    color: #333;
}

.table-row:nth-child(even) {
    background-color: #f9f9f9;
}

.table-row:hover {
    background-color: #f1f1f1;
    transition: background-color 0.3s;
}
.head-container{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.total-count{
    font-weight: bolder;
    font-size: 36px;
    font-family: 'Times New Roman', Times, serif;
    align-self: flex-end;
    color:red
}
.center-name{
    font-weight: bolder;
    font-family: 'Times New Roman', Times, serif;
    align-self: flex-end;
    color:red
}
.table-filter-container{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.filter-container{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.date-filter {
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none;
}

.date-range-filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.date-range-filter label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.date-range-filter input {
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none;
}

.date-range-filter button {
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
}

.date-range-filter button:hover {
    background-color: #0056b3;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
