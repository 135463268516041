.admission-centers-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.admission-center-card {
  width: 300px;
  padding: 20px;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.admission-center-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.center-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.total-admissions {
  font-size: 16px;
  color: #555;
}
