.add-course-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    background-color: white;
    height: 100vh;
  }
  
  .add-course-form {
    width: 100%;
    border: 1px solid rgb(76, 221, 173);
    max-width: 400px;
    padding:50px;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    background-color: #cadfb2;
}
  
  .add-course-field {
    margin-bottom: 15px;
  }
  
  .add-course-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .add-course-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .add-course-input.add-course-error {
    border-color: #e74c3c;
  }
  
  .add-course-error-message {
    color: #e74c3c;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .add-course-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .add-course-button:hover {
    background-color: #0056b3;
  }
  