.all-center-courses-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.all-center-courses-search-bar {
  margin-bottom: 20px;
}

.all-center-courses-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.all-center-courses-dropdowns {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.all-center-courses-select {
  flex: 1;
  padding: 10px;
  font-size: 16px;
}

.all-center-courses-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.all-center-courses-card {
  flex: 1 1 calc(33.333% - 20px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  transition: transform 0.3s;
}

.all-center-courses-card:hover {
  transform: translateY(-5px);
}

.all-center-courses-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.all-center-courses-details {
  padding: 20px;
}

.all-center-courses-name {
  font-size: 18px;
  margin-bottom: 10px;
}

.all-center-courses-category, .all-center-courses-fees, .all-center-courses-duration {
  font-size: 16px;
  margin-bottom: 5px;
}

.loading-view, .error-view, .no-data-view {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.loading-view {
  color: #007bff;
}

.error-view {
  color: red;
}

.no-data-view {
  color: #555;
}
