/* SearchEnroll.css */
.search-enroll-container {
    background-color: #fff;
    color: #000;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
    animation: fadeIn 1s ease-in-out;
  }
  
  .search-box {
    display: flex;
    margin-bottom: 20px;
  }
  
  .input-field {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: calc(100% - 100px);
    margin-right: 10px;
  }
  
  .get-button {
    padding: 10px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .get-button:hover {
    background-color: #444;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .data-table th, .data-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  .enroll-button {
    padding: 5px 10px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .enroll-button:hover {
    background-color: #444;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  