.student-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
}

.input-container {
  display: flex;
  margin-bottom: 20px;
}

.student-input {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  transition: border-color 0.3s;
}

.student-input:focus {
  border-color: #007bff;
}

.search-button {
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-button:hover {
  background-color: #0056b3;
}

.loading-view {
  color: #007bff;
  font-size: 18px;
  margin-top: 20px;
}

.error-message {
  color: red;
  font-size: 18px;
  margin-top: 20px;
}

.initial-view {
  color: #555;
  font-size: 18px;
  margin-top: 20px;
}

.student-card {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  animation: fadeIn 0.5s;
}

.student-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 10px;
  object-fit: cover;
}

.student-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.student-name {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.student-course,
.student-timings,
.student-start-date,
.paid-fees,
.student-total-fees {
  margin: 5px 0;
  font-size: 16px;
  color: #555;
}

.pay-fees-button {
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pay-fees-button:hover {
  background-color: #218838;
}

/* Modal Overlay */
.pay-fee-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255); /* Add a semi-transparent background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 900px;
  max-width: 40%;
  position: relative;
  animation: slideIn 0.3s;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #555;
}

.modal-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.modal-label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #111111;
}

.modal-input {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.modal-input:focus {
  border-color: #007bff;
}

.modal-pay-button {
  padding: 10px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-pay-button:hover {
  background-color: #0056b3;
}

/* Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
